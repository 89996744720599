/* src/app/shared/components/drag-and-drop-upload-file/drag-and-drop-upload-file.component.scss */
.content-page {
  background-color: transparent;
  color: #275fa1;
}
.content-page-disabled {
  background-color: transparent;
  color: #6c757d;
  opacity: 0.5;
  pointer-events: none;
}
.icon-update {
  font-size: 45px;
}
.upload-file-container {
  height: 118px;
  width: 35vw;
  border: 1px dashed darkgrey;
  border-radius: 6px;
  cursor: pointer;
}
.file-content {
  margin: 20px auto;
}
.delete-file-icon {
  cursor: pointer;
  margin-top: 4%;
  color: #5b6c80;
  margin-left: 7px;
}
/*# sourceMappingURL=drag-and-drop-upload-file.component-GCMRPUYZ.css.map */
