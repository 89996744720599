<div class="container-fluid ">

    <div class="d-flex upload-file-container justify-content-center align-items-center text-center"
         [ngClass]="{'content-page': !this.disabled, 'content-page-disabled': this.disabled}" appDragAndDrop
         (fileDropped)="uploadFile($event)"
         (click)="fileInput.click()"
         matRipple>
        <div *ngIf="this.file === null">
            <div class="row">
                <span class="material-icons col icon-update">
                    backup
                </span>
            </div>
            <div class="row align-self-center">
                <span class="col" translate>drag-drop.description</span>
            </div>
        </div>

        <div *ngIf="this.file !== null && !multipleFiles" class="col-10 file-content mt-6">
            <div class="row justify-content-center">
                <div class="col-10">
                    <span appEllipsify> {{ getFileName(this.file) }} </span>
                    <i class="material-icons delete-file-icon" (click)="deleteAttachment()">delete</i>
                </div>
            </div>
        </div>
    </div>
    <input hidden type="file" accept="{{fileType}}" #fileInput (change)="uploadFile($event)" (click)="handleSameFile($event)" [multiple]="multipleFiles">
</div>


