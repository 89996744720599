import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DragAndDropUploadFileComponent} from './drag-and-drop-upload-file.component';
import {DragAndDropModule} from '../../../core/directives/drag-and-drop/drag-and-drop.module';
import {TranslateModule} from '@ngx-translate/core';
import {EllipsifyModule} from '../../../core/directives/ellipsify/ellipsify.module';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
    imports: [
        CommonModule,
        DragAndDropModule,
        TranslateModule,
        EllipsifyModule,
        MatRippleModule
    ],
    declarations: [DragAndDropUploadFileComponent],
    exports: [
        DragAndDropUploadFileComponent
    ],
})
export class DragAndDropUploadFileModule {
}
