import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { formatFileName } from 'src/app/utils/file.utils';
import { formatBytes } from '../../../utils/converter.utils';

@Component({
    selector: 'app-drag-and-drop-upload-file',
    templateUrl: './drag-and-drop-upload-file.component.html',
    styleUrls: ['./drag-and-drop-upload-file.component.scss'],
})
export class DragAndDropUploadFileComponent {
    @ViewChild('fileInput')
    fileSelectedRef: ElementRef;

    @Input() fileType: '*.*' | 'image/*' = '*.*';
    @Input() multipleFiles: false | true = false;
    @Input() disabled = false;

    @Output() fileSelected = new EventEmitter();
    file: any = null;
    files: File[] = [];

    onFileSelected(item) {
        this.fileSelected.emit(item);
    }

    uploadFile(event) {
        if (this.multipleFiles) {
            this.handleMultipleFiles(event);
        } else {
            if (event[0]) {
                this.onFileSelected(event[0]);
                this.file = event[0];
            } else {
                this.onFileSelected(event.target.files[0]);
                this.file = event.target.files[0];
            }
        }
    }

    getFileName(file: any) {
        return file.size > 0 ? `${file.name} (${formatBytes(file.size)})` : file.name;
    }

    deleteAttachment(file?: any) {
        this.onFileSelected(null);
        this.file = null;
        this.fileSelectedRef.nativeElement.value = '';
    }

    resetAttachment() {
        this.file = null;
        this.fileSelectedRef.nativeElement.value = '';
    }

    private handleMultipleFiles(event) {
        this.files = [];
        const files = event[0] ? event : event.target.files;
        for (const file of files) {
            const newFile = this.handleFileName(file);
            this.files.push(newFile);
        }
        this.onFileSelected(this.files);
    }

    private handleFileName(file: File): File {
        let name = formatFileName(file.name);
        name = name.slice(0, name.lastIndexOf('.')) + '_' +
            Date.now().toString() +
            name.slice(name.lastIndexOf('.'));

        return new File([file], name, {
            type: file.type,
            lastModified: file.lastModified,
        });
    }

    handleSameFile(event: any) {
        event.target.value = '';
    }
}
